import Send from '../../trans.client.js'

export default {
  // B/L 첨부파일 정보 가져오기
  getBLAttachList (params) {
    return Send({
      url: '/trans/bl/' + params.blNo + '/attach',
      method: 'get',
      params: params
    })
  },
  // B/L 첨부파일 정보 저장
  popBLAttachUpload (params) {
    return Send({
      url: '/trans/bl/' + params.blNo + '/attach',
      method: 'post',
      data: params
    })
  },
  // B/L 첨부파일(LOI-UPLOAD) 정보 가져오기
  getBLAttachLoiUploadList (blNo) {
    return Send({
      url: '/trans/bl/' + blNo + '/attach/loi-upload',
      method: 'get'
    })
  }
}
